import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (

    <div className="pt-[120px] px-8">
      <h1>404 NOT FOUND</h1>
      <p>The page you requested could not be found.</p>
    </div>

);

export default NotFoundPage;
